import React from "react";
import theme from "theme";
import { Theme, Image, Box, Text, Section, Em, Link } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Crystal Clean Carwash
			</title>
			<meta name={"description"} content={"Блиск, на який заслуговує ваше авто"} />
			<meta property={"og:title"} content={"Crystal Clean Carwash"} />
			<meta property={"og:description"} content={"Блиск, на який заслуговує ваше авто"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/66795e7df0cb1d0025f67f4d/images/pngtree-car-wash-icon-blue-liquid-logo-vector-png-image_615059.png?v=2024-06-25T08:43:35.281Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/66795e7df0cb1d0025f67f4d/images/pngtree-car-wash-icon-blue-liquid-logo-vector-png-image_615059.png?v=2024-06-25T08:43:35.281Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/66795e7df0cb1d0025f67f4d/images/pngtree-car-wash-icon-blue-liquid-logo-vector-png-image_615059.png?v=2024-06-25T08:43:35.281Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/66795e7df0cb1d0025f67f4d/images/pngtree-car-wash-icon-blue-liquid-logo-vector-png-image_615059.png?v=2024-06-25T08:43:35.281Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/66795e7df0cb1d0025f67f4d/images/pngtree-car-wash-icon-blue-liquid-logo-vector-png-image_615059.png?v=2024-06-25T08:43:35.281Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/66795e7df0cb1d0025f67f4d/images/pngtree-car-wash-icon-blue-liquid-logo-vector-png-image_615059.png?v=2024-06-25T08:43:35.281Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/66795e7df0cb1d0025f67f4d/images/pngtree-car-wash-icon-blue-liquid-logo-vector-png-image_615059.png?v=2024-06-25T08:43:35.281Z"} />
		</Helmet>
		<Components.Header>
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="text" />
			<Override slot="text1" />
			<Override slot="text2" />
			<Override slot="text3" />
			<Override slot="link2" />
			<Override slot="link3" />
			<Override slot="link4" />
		</Components.Header>
		<Section md-padding="70px 0 80px 0" quarkly-title="Product-2">
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-template-rows="auto"
				md-grid-template-columns="1fr"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
					background="rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/66795e7df0cb1d0025f67f4d/images/pngtree-car-wash-icon-blue-liquid-logo-vector-png-image_615059.png?v=2024-06-25T08:43:35.281Z) 0 0/contain no-repeat scroll padding-box"
					padding="100px 50px 100px 50px"
					md-margin="0px 0px 50px 0px"
					md-padding="50px 50px 50px 50px"
				>
					<Image
						src="https://uploads.quarkly.io/66795e7df0cb1d0025f67f4d/images/1-2.jpg?v=2024-06-25T08:43:35.269Z"
						display="block"
						max-width="100%"
						width="350px"
						border-radius="30px"
						srcSet="https://smartuploads.quarkly.io/66795e7df0cb1d0025f67f4d/images/1-2.jpg?v=2024-06-25T08%3A43%3A35.269Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66795e7df0cb1d0025f67f4d/images/1-2.jpg?v=2024-06-25T08%3A43%3A35.269Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66795e7df0cb1d0025f67f4d/images/1-2.jpg?v=2024-06-25T08%3A43%3A35.269Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66795e7df0cb1d0025f67f4d/images/1-2.jpg?v=2024-06-25T08%3A43%3A35.269Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66795e7df0cb1d0025f67f4d/images/1-2.jpg?v=2024-06-25T08%3A43%3A35.269Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66795e7df0cb1d0025f67f4d/images/1-2.jpg?v=2024-06-25T08%3A43%3A35.269Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66795e7df0cb1d0025f67f4d/images/1-2.jpg?v=2024-06-25T08%3A43%3A35.269Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
					justify-content="center"
					padding="0px 40px 0px 40px"
					lg-padding="0px 0 0px 0"
				>
					<Text margin="0px 0px 40px 0px" font="--headline3" sm-margin="0px 0px 30px 0px">
						У Crystal Clean Carwash ми віримо, що чистий автомобіль — це більше, ніж просто красиве видовище, це відображення вашої гордості за свій автомобіль.
					</Text>
					<Text margin="0px 0px 50px 0px" font="--lead" color="#656b70">
						Наше сучасне обладнання покликане надати вам неперевершений досвід автомийки, гарантуючи, що ваше авто виглядатиме та почуватиметься якнайкраще щоразу, коли ви відвідаєте.
					</Text>
					<Box
						min-width="10px"
						min-height="10px"
						display="flex"
						lg-align-self="center"
						md-align-self="auto"
						sm-flex-direction="column"
						sm-align-items="center"
						sm-align-self="center"
					/>
				</Box>
			</Box>
		</Section>
		<Section
			padding="60px 0 60px 0"
			sm-padding="40px 0"
			sm-min-height="auto"
			display="flex"
			background="--color-darkL2"
			quarkly-title="Advantages/Features-10"
		>
			<Override
				slot="SectionContent"
				flex-direction="row"
				flex-wrap="wrap"
				grid-gap="5%"
				sm-min-width="280px"
			/>
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				justify-content="center"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
				sm-width="100%"
				md-margin="0px 0px 30px 0px"
				margin="0px 0px 64px 0px"
				lg-justify-content="flex-start"
			>
				<Text
					as="h1"
					margin="0px"
					font="--headline2"
					color="--light"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					sm-font="normal 600 40px/1.2 --fontFamily-sans"
					text-align="left"
				>
					Про нас
				</Text>
				<Text
					as="p"
					margin="20px 0 0 0"
					font="--lead"
					font-size="20px"
					font-weight="300"
					color="--lightD2"
					width="90%"
					md-width="100%"
					md-margin="0px 0px 0px 0px"
					md-padding="0px 0px 0px 0px"
					sm-font="--base"
					text-align="left"
					lg-width="100%"
					lg-max-width="720px"
				>
					Crystal Clean Carwash було засновано з місією надавати першокласні послуги автомийки з використанням новітніх технологій та екологічно чистих методів. Наша команда досвідчених професіоналів захоплюється автомобілями та прагне запропонувати першокласне обслуговування під час кожного миття. Ми розуміємо важливість підтримки зовнішнього вигляду та вартості вашого автомобіля, і ми прагнемо перевершити ваші очікування з кожним візитом.
				</Text>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				padding="0px 160px 0px 0px"
				border-radius="24px"
				margin="0px 0px 0px 0px"
				width="40%"
				lg-padding="0px 50px 0px 0px"
				lg-width="50%"
				md-width="100%"
				md-padding="0px 0px 0px 0px"
				display="flex"
				flex-direction="column"
				align-items="flex-start"
				md-margin="0px 0px 60px 0px"
				md-align-items="center"
				align-self="center"
			>
				<Text
					margin="0px 0px 16px 0px"
					font="--headline2"
					color="--dark"
					md-text-align="center"
					sm-font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Чому обирають нас
				</Text>
				<Text
					margin="0px 0px 40px 0px"
					font="--lead"
					color="--greyD3"
					md-text-align="center"
					md-margin="0px 0px 20px 0px"
				>
					<Em>
						Crystal Clean Carwash – блиск, на який заслуговує ваше авто
					</Em>
				</Text>
				<Link
					href="/services"
					text-decoration-line="initial"
					color="--light"
					font="--lead"
					hover-opacity="0.9"
					background="--color-indigo"
					border-radius="8px"
					padding="11px 24px 12px 24px"
				>
					Послуги
				</Link>
			</Box>
			<Box
				display="flex"
				lg-flex-wrap="wrap"
				width="60%"
				flex-direction="column"
				lg-width="50%"
				md-width="100%"
			>
				<Box
					width="100%"
					display="flex"
					flex-direction="row"
					margin="0px 0px 40px 0px"
					lg-margin="0px 4% 15px 0px"
					sm-width="100%"
					sm-margin="0px 0px 30px 0px"
				>
					<Text
						padding="7px 24px 8px 24px"
						margin="0px 30px 0px 0px"
						font="--headline3"
						border-radius="50px"
						align-items="center"
						display="flex"
						justify-content="center"
						color="--darkL2"
						width="49px"
						height="49px"
						background="--color-lightD1"
						sm-margin="0px 14px 0px 0px"
					>
						1
					</Text>
					<Box>
						<Text margin="0px 0px 0px 0px" color="--greyD3" font="--lead" lg-text-align="left">
							Передова технологія: Ми використовуємо новітню технологію автомийки, щоб забезпечити ретельне та ефективне очищення щоразу.
						</Text>
					</Box>
				</Box>
				<Box
					width="100%"
					display="flex"
					flex-direction="row"
					margin="0px 0px 40px 0px"
					lg-margin="0px 4% 15px 0px"
					sm-width="100%"
					sm-margin="0px 0px 30px 0px"
				>
					<Text
						padding="7px 24px 8px 24px"
						margin="0px 30px 0px 0px"
						font="--headline3"
						border-radius="50px"
						align-items="center"
						display="flex"
						justify-content="center"
						color="--darkL2"
						width="49px"
						height="49px"
						background="--color-lightD1"
						sm-margin="0px 14px 0px 0px"
					>
						2
					</Text>
					<Box>
						<Text margin="0px 0px 0px 0px" color="--greyD3" font="--lead" lg-text-align="left">
							Екологічно чисті продукти: наші миючі засоби є екологічно чистими, безпечними для вашого автомобіля та ефективними проти бруду.
						</Text>
					</Box>
				</Box>
				<Box
					width="100%"
					display="flex"
					flex-direction="row"
					margin="0px 0px 40px 0px"
					lg-margin="0px 4% 15px 0px"
					sm-width="100%"
					sm-margin="0px 0px 30px 0px"
				>
					<Text
						padding="7px 24px 8px 24px"
						margin="0px 30px 0px 0px"
						font="--headline3"
						border-radius="50px"
						align-items="center"
						display="flex"
						justify-content="center"
						color="--darkL2"
						width="49px"
						height="49px"
						background="--color-lightD1"
						sm-margin="0px 14px 0px 0px"
					>
						3
					</Text>
					<Box>
						<Text margin="0px 0px 0px 0px" color="--greyD3" font="--lead" lg-text-align="left">
							Досвідчений персонал: Наша команда навчена дбайливо й акуратно поводитися з усіма типами транспортних засобів.
						</Text>
					</Box>
				</Box>
				<Box
					width="100%"
					display="flex"
					flex-direction="row"
					margin="0px 0px 40px 0px"
					lg-margin="0px 4% 15px 0px"
					sm-width="100%"
					sm-margin="0px 0px 30px 0px"
				>
					<Text
						padding="7px 24px 8px 24px"
						margin="0px 30px 0px 0px"
						font="--headline3"
						border-radius="50px"
						align-items="center"
						display="flex"
						justify-content="center"
						color="--darkL2"
						width="49px"
						height="49px"
						background="--color-lightD1"
						sm-margin="0px 14px 0px 0px"
					>
						4
					</Text>
					<Box>
						<Text margin="0px 0px 0px 0px" color="--greyD3" font="--lead" lg-text-align="left">
							Зручне розташування: Наша автомийка розташована у зручному місці, щоб краще обслуговувати вас.
						</Text>
					</Box>
				</Box>
				<Box
					width="100%"
					display="flex"
					flex-direction="row"
					margin="0px 0px 40px 0px"
					lg-margin="0px 4% 15px 0px"
					sm-width="100%"
					sm-margin="0px 0px 30px 0px"
				>
					<Text
						padding="7px 24px 8px 24px"
						margin="0px 30px 0px 0px"
						font="--headline3"
						border-radius="50px"
						align-items="center"
						display="flex"
						justify-content="center"
						color="--darkL2"
						width="49px"
						height="49px"
						background="--color-lightD1"
						sm-margin="0px 14px 0px 0px"
					>
						5
					</Text>
					<Box>
						<Text margin="0px 0px 0px 0px" color="--greyD3" font="--lead" lg-text-align="left">
							Гарантовано задоволення: Ми підтримуємо якість нашої роботи та пропонуємо гарантію задоволення від усіх наших послуг.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-12">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
				width="100%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			>
				<Box
					overflow-y="hidden"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					width="100%"
					height="auto"
					overflow-x="hidden"
					position="relative"
					padding="0px 0px 70% 0px"
				>
					<Image
						width="100%"
						top="auto"
						left={0}
						bottom="0px"
						min-height="100%"
						src="https://uploads.quarkly.io/66795e7df0cb1d0025f67f4d/images/2-4.jpg?v=2024-06-25T08:43:35.280Z"
						object-fit="cover"
						right={0}
						position="absolute"
						display="block"
						srcSet="https://smartuploads.quarkly.io/66795e7df0cb1d0025f67f4d/images/2-4.jpg?v=2024-06-25T08%3A43%3A35.280Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66795e7df0cb1d0025f67f4d/images/2-4.jpg?v=2024-06-25T08%3A43%3A35.280Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66795e7df0cb1d0025f67f4d/images/2-4.jpg?v=2024-06-25T08%3A43%3A35.280Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66795e7df0cb1d0025f67f4d/images/2-4.jpg?v=2024-06-25T08%3A43%3A35.280Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66795e7df0cb1d0025f67f4d/images/2-4.jpg?v=2024-06-25T08%3A43%3A35.280Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66795e7df0cb1d0025f67f4d/images/2-4.jpg?v=2024-06-25T08%3A43%3A35.280Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66795e7df0cb1d0025f67f4d/images/2-4.jpg?v=2024-06-25T08%3A43%3A35.280Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
			</Box>
			<Box
				flex-direction="column"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				display="flex"
				width="100%"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					sm-font="--headline3"
					sm-margin="0px 0px 30px 0px"
					md-text-align="left"
				>
					У Crystal Clean Carwash ми пропонуємо різноманітні послуги, щоб задовольнити всі ваші потреби щодо чищення автомобіля.{" "}
				</Text>
			</Box>
			<Box
				flex-direction="column"
				padding="16px 16px 16px 16px"
				display="flex"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				width="33.3333%"
			>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					sm-margin="0px 0px 30px 0px"
					md-text-align="left"
				>
					Наше комплексне меню гарантує, що ваш автомобіль отримає саме той догляд, який йому потрібен, від швидкого зовнішнього миття до повного обслуговування деталей. Наше сучасне мийне обладнання та високоякісні засоби для чищення створені, щоб справлятися навіть із найсильнішим брудом і брудом, залишаючи ваш автомобіль блискучим і захищеним.
				</Text>
			</Box>
			<Box
				display="flex"
				flex-direction="column"
				align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				width="33.3333%"
				lg-width="100%"
				lg-align-items="center"
				sm-padding="0px 0px 0px 0px"
				lg-padding="0px 16px 16px 16px"
			>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					sm-margin="0px 0px 30px 0px"
					md-text-align="left"
				>
					У Crystal Clean Carwash ми більше, ніж просто автомийка – ми є частиною подорожі вашого автомобіля. Ми докладаємо всіх зусиль, щоб ваш автомобіль виглядав якнайкраще, починаючи від регулярного технічного миття та закінчуючи спеціальними деталями. Дякуємо, що вибрали нас, і ми з нетерпінням чекаємо, щоб незабаром обслуговувати вас.
				</Text>
			</Box>
			<Box
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
				display="flex"
				width="33.3333%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			>
				<Box
					overflow-x="hidden"
					hover-transform="translateY(-10px)"
					padding="0px 0px 60% 0px"
					position="relative"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					width="100%"
					height="auto"
					overflow-y="hidden"
				>
					<Image
						bottom="0px"
						min-height="100%"
						src="https://uploads.quarkly.io/66795e7df0cb1d0025f67f4d/images/2-1.jpg?v=2024-06-25T08:43:35.270Z"
						display="block"
						width="100%"
						left={0}
						right={0}
						object-fit="cover"
						position="absolute"
						top="auto"
						srcSet="https://smartuploads.quarkly.io/66795e7df0cb1d0025f67f4d/images/2-1.jpg?v=2024-06-25T08%3A43%3A35.270Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66795e7df0cb1d0025f67f4d/images/2-1.jpg?v=2024-06-25T08%3A43%3A35.270Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66795e7df0cb1d0025f67f4d/images/2-1.jpg?v=2024-06-25T08%3A43%3A35.270Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66795e7df0cb1d0025f67f4d/images/2-1.jpg?v=2024-06-25T08%3A43%3A35.270Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66795e7df0cb1d0025f67f4d/images/2-1.jpg?v=2024-06-25T08%3A43%3A35.270Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66795e7df0cb1d0025f67f4d/images/2-1.jpg?v=2024-06-25T08%3A43%3A35.270Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66795e7df0cb1d0025f67f4d/images/2-1.jpg?v=2024-06-25T08%3A43%3A35.270Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
			</Box>
		</Section>
		<Components.Header />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"666ac2cae8900d0022f35234"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});